/**
 * @packageDocumentation
 * @module app/bms-router
 *
 */
import { createMachine } from "xstate";
import history from "history/browser";

export type GOTO = "GOTO";
export type Context = {}; // Partial<{ userId: number | undefined }>
export type Event = { type: GOTO; route: string; redirected?: boolean };

export const GOTO: GOTO = "GOTO";

export const config = {
  id: "routes",
  initial: "home",
  context: {
    redirected: false,
  },
  on: {
    GOTO: [
      { actions: "updateLocation", target: ".home", cond: "home" },
      { actions: "updateLocation", target: ".login", cond: "login" },
      { actions: "updateLocation", target: ".enable-bms", cond: "enable-bms" },
      {
        actions: "updateLocation",
        target: ".bms-enabled",
        cond: "bms-enabled",
      },
      { actions: "updateLocation", target: ".signup", cond: "signup" },
      { actions: "updateLocation", target: ".saad", cond: "saad" },
      { actions: "updateLocation", target: ".saad-apply", cond: "saad-apply" },
      {
        actions: "updateLocation",
        target: ".sn-signup/asurion",
        cond: "sn-signup/asurion",
      },
      // { target: ".contactUs", cond: "contactUs" },
      {
        actions: "updateLocation",
        target: ".layout-example",
        cond: "layout-example",
      },
      { actions: "updateLocation", target: ".404" },
    ],
  },
  states: {
    home: {},
    signup: {},
    saad: {},
    login: {},
    "enable-bms": {},
    "bms-enabled": {},
    "saad-apply": {},
    "layout-example": {},
    "sn-signup/asurion": {},
    // contactUs: {},
    404: {},
  },
};

export const guards = {
  home: (_: Context, e: Event) => e.route === "home",
  login: (_: Context, e: Event) => e.route === "login",
  signup: (_: Context, e: Event) => e.route === "signup",
  saad: (_: Context, e: Event) => e.route === "saad",
  "saad-apply": (_: Context, e: Event) => e.route === "saad-apply",
  "layout-example": (_: Context, e: Event) => e.route === "layout-example",
  // contactUs: (_: Context, e: Event) => e.route === "contact-us",
  "enable-bms": (_: Context, e: Event) => e.route === "enable-bms",
  "bms-enabled": (_: Context, e: Event) => e.route === "bms-enabled",
  "sn-signup/asurion": (_: Context, e: Event) =>
    e.route === "sn-signup/asurion",
};

export const actions = {
  updateLocation: (_: Context, evt: Event) => {
    const route = /^\//.test(evt.route) ? evt.route : `/${evt.route}`;

    if (evt.redirected) history.replace(route);
    else history.push(route);
  },
};

export const machine = createMachine(config, { actions, guards });
