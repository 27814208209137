/**
 * @packageDocumentation
 * @module app/bms
 *
 */
import classes from "./bms.module.scss";

import * as React from "react";

/**
 * ReactJS props for the [[Bottom]] component.
 */
type propsBottom = {
  children: React.ReactNode;
  smallScreen?: boolean;
  padded?: boolean;
};

/**
 * _React Component_
 *
 * ### Bottom
 *
 * Helper component for laying out content within the project's design. This component is used
 * to force content to "stick" to the bottom of small screens when the content does not exceed
 * the screen's height. This makes it easy to give users quick thumb access to controls.
 *
 * E.g.: You might have a login form in the center with a submit button at the bottom.
 *
 *
 * @param props
 */
export const Bottom = (props: propsBottom) => (
  <div
    className={classes.bottom}
    data-padded={!!props.padded}
    data-small-screen={!!props.smallScreen}
  >
    <div>{props.children}</div>
  </div>
);

export default Bottom;
