/**
 * @packageDocumentation
 * @module app/bms
 *
 */
import copy from "./copy.json";
import classes from "./bms.module.scss";
import serviceBenchLogo from "./images/sb-logo.svg";
import serviceBenchTextLogo from "./images/sb-text-logo-white.svg";

import * as React from "react";
import { useObservableState } from "observable-hooks";
import { useMachine } from "@xstate/react";

import { useTranslation } from "../../modules/utils";
import { SendContext } from "../../modules/components/SendContext";
import { useServiceLogger } from "../../modules/xstate";
import { isAuthenticated$, logout } from "../../modules/streams/authentication";
import { SecondaryButton } from "../../modules/components/input-controls/Button";

import Router, { machine as routerMachine } from "../bms-router";
import Alerts from "../alert";

/**
 * _React Component_
 *
 * ### BMS
 *
 * Main application for this repositories project. Implements the
 * _bms-router_ application and the main layout.
 */
export const BMS = () => {
  // Use the router state machine
  const [state, send, service] = useMachine(routerMachine);

  // Use the hook providing the text translator
  const [t] = useTranslation();

  // Subscribe to the stream that indicates if the user is authenticated or not.
  const isAuthenticated = useObservableState(isAuthenticated$);

  // Log router state machine activities to the console
  useServiceLogger(service, "bms.router");

  return (
    /**
     * This ReactJS context provider makes the interpreted router state machine's service send/dispatch
     * function available to Link components. Since routing is done by sending events to the
     * router machine.
     */
    <SendContext.Provider value={{ state, send }}>
      {/* Application header/topbar */}
      {/* eslint-disable-next-line react/jsx-no-literals */}
      <nav className={`sb-theme ${classes.nav}`}>
        <div className={classes.logos}>
          <img
            className={classes.logo}
            alt="ServiceBench"
            title="ServiceBench (logo)"
            aria-hidden="true"
            src={serviceBenchLogo}
          />
          <img
            className={classes.textLogo}
            alt="ServiceBench"
            title="ServiceBench (logo)"
            src={serviceBenchTextLogo}
          />
        </div>

        <div className={classes.navRight}>
          {isAuthenticated ? (
            <SecondaryButton
              onClick={() => {
                send({ type: "GOTO", route: "home" });
                logout();
              }}
            >
              {t(copy.logoutButtonLabel)}
            </SecondaryButton>
          ) : null}
        </div>
      </nav>
      <div className={classes.body}>
        <section className={`sb-theme ${classes.content}`}>
          <Alerts />
          <Router send={send} state={state} />
        </section>
        <footer
          className={classes.footer}
          // Hide the footer for non-bms routes
          data-hide={/saad|sn-signup/.test(state.value as string)}
        >
          <div>
            <div>
              {t(copy.copyright, { values: [new Date().getFullYear()] })}
            </div>
          </div>

          <div>
            <a
              href="https://www.asurion.com/terms-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(copy.linkTermsOfUse)}
            </a>
            <a
              href="https://www.asurion.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(copy.linkPrivacyPolicy)}
            </a>
          </div>
        </footer>
      </div>
    </SendContext.Provider>
  );
};

export default BMS;
