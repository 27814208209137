import {
  EventObject,
  InterpreterOptions,
  Interpreter,
  State,
  StateMachine,
  MachineOptions,
  StateConfig,
} from "xstate";

import { useMachine } from "@xstate/react";
import {
  RegisteredMachine,
  RegisteredMachinesMap,
  StateWithMatches,
  InterpreterWithMatches,
} from "@xstate/compiled";
import { useMachine as useCompiledMachine_ } from "@xstate/compiled/react";

/**
 * useCompiledMachine is a wrapper around @xstate/react's useMachine but
 * imported from @xstate/compiled/react which in turn is code-generated by
 * xstate-codegen.
 *
 * @param machine
 * @param options
 */
export const useCompiledMachine = <
  TContext,
  TSchema,
  TEvent extends EventObject,
  Id extends keyof RegisteredMachinesMap<TContext, TEvent>
>(
  machine: Extract<RegisteredMachine<TContext, TEvent>, { id: Id }>,
  options: Extract<RegisteredMachine<TContext, TEvent>, { id: Id }>["_options"]
): [
  StateWithMatches<TContext, TEvent, Id>,
  InterpreterWithMatches<TContext, TSchema, TEvent, Id>["send"],
  InterpreterWithMatches<TContext, TSchema, TEvent, Id>
] =>
  useCompiledMachine_(
    machine,
    // @ts-ignore
    Object.assign({}, options || {}, {
      devTools: process.env.NODE_ENV === "development",
    })
  );

type RemoveEventsWithPayload<T extends { type: string }> = T extends any
  ? keyof Omit<T, "type"> extends never
    ? T["type"]
    : never
  : never;

type TypeOnlyEvent<T extends EventObject> = RemoveEventsWithPayload<T>;

// Interpreter['send']
export interface Send<TEvent extends EventObject> {
  (event: TEvent | TypeOnlyEvent<TEvent>): void;
  <T extends Exclude<TEvent, { type: TypeOnlyEvent<TEvent> }>>(
    event: T["type"],
    payload: Omit<T, "type">
  ): void;
}

interface UseMachineOptions<TContext, TEvent extends EventObject> {
  /**
   * If provided, will be merged with machine's `context`.
   */
  context?: Partial<TContext>;
  /**
   * If `true`, service will start immediately (before mount).
   */
  immediate: boolean;
  /**
   * The state to rehydrate the machine to. The machine will
   * start at this state instead of its `initialState`.
   */
  state?: StateConfig<TContext, TEvent>;
}

interface UseMachine {
  <TContext, TEvent extends EventObject>(
    machine: StateMachine<TContext, any, TEvent>,
    options?: Partial<InterpreterOptions> &
      Partial<UseMachineOptions<TContext, TEvent>> &
      Partial<MachineOptions<TContext, TEvent>>
  ): [
    State<TContext, TEvent>,
    Send<TEvent>,
    Interpreter<TContext, any, TEvent>
  ];
}

const useMachine_local: UseMachine = (config, options = {}) =>
  useMachine(
    config,
    Object.assign({}, options, {
      devTools: process.env.NODE_ENV === "development",
    })
  );

export default useMachine_local;

if (process.env.NODE_ENV === "development") {
  const params = new URLSearchParams(window.location.search);

  if (params.get("inspect") !== "false") {
    (async function () {
      const { inspect } = await import("@xstate/inspect");

      inspect({
        url: "https://statecharts.io/inspect",
        iframe: false,
      });
    })();
  }
}
