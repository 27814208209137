/**
 * @packageDocumentation
 * @module app/alert
 */
import * as React from "react";
import { BehaviorSubject } from "rxjs";

import { Failure, isApiFailure, isErrorFailure } from "../../modules/apis/q";

/**
 * An alert. Besides the status the ReactJS node is also stored on this object. This gives
 * applications a modules the freedom (and responsible power) to render what ever content they
 * want the alert to display. This makes it esspecially easy to translate alert messages in different
 * languages. This is why in most cases the node is just a span tag with a translated message.
 */
export type Alert = {
  /**
   * Status of the alert. When rendered, alerts may be colourised differently based on their status.
   */
  status: "error" | "warning" | "success";
  /**
   * ReactJS node that is rendered as part of the list of alerts.
   */
  node: React.ReactNode;
};

/**
 * Push-based stream that is the state of this application and contains the list of alerts to be displayed.
 */
export const alerts$ = new BehaviorSubject<Alert[]>([]);

/**
 * Adds an alert to the alert app's state. The alert added is also
 * returned. Ths allows modules and application pushing the alert
 * to keep a reference to it and clear it later.
 *
 * @returns The alert added to state.
 */
export const alert = (alert: Alert) => {
  alerts$.next([...alerts$.value, alert]);
  return alert;
};

/**
 * Removes the given alert from state. Note, the alert is an object and matched by reference.
 */
export const clear = (alert?: Alert) =>
  alerts$.next(
    alert ? alerts$.value.filter((current) => current !== alert) : []
  );

/**
 * Given an alert node/message, creates an "error" alert, pushes it to state and returns the created alert.
 */
export const error = (node: React.ReactNode) =>
  alert({ status: "error", node });

/**
 * Given an alert node/message, creates a "warning" alert, pushes it to state and returns the created alert.
 */
export const warning = (node: React.ReactNode) =>
  alert({ status: "warning", node });

/**
 * Given an alert node/message, creates a "success" alert, pushes it to state and returns the created alert.
 */
export const success = (node: React.ReactNode) =>
  alert({ status: "success", node });

/**
 * Helper function for creating error alert(s) given the [[Failure]] type. This function
 * is heavily used by state machines responding to network requests that have failed or
 * returned an error.
 *
 */
export const fromFailure = (failure: Failure) => {
  if (isApiFailure(failure)) {
    return failure.error.errors.map((_) => error(_.message));
  } else if (isErrorFailure(failure)) {
    return [error("An unknown error has occured.")];
  }

  return [error("The server responded with an unknown messsage.")];
};
