/**
 * ## app/alert
 * 
 * _alert_ has the beginnings of an app for storing and displaying application wide alerts
 * It provides functions for updating a state with _success_, _warning_ or _error_ alerts.
 * The state for this application (list of alerts) is managed using a push-based stream.
 * Other applications can push new alerts to this stream if they need a message displayed.
 * 
 * The application also provides a component that can be rendereded wherever you want the
 * alerts to show up in the project's layout. Currently this is rendered underneath the
 * application header layout.
 * 
 * Cleaning up alerts is the responsibility of modules and applications using the _alerts_ app.
 * All it does is provide functions to create alerts and component to render them in one place.
 * 
 * The stream stores a list of [[Alert]] objects that have an `.status` and
 * a `.node` property. The _status_ of an alert is used to style/colourise differently. The _node_
 * property contains the alert message. Rather than developers providing a string to the app they
 * should provide a ReactJS node as this allows them to render translated messages or even add 
 * images or style the message differently.
 * 
 * The most frequently used functions are:
 * 
 * - [[clear]]
 * - [[error]]
 * - [[warning]]
 * - [[success]]
 * - [[fromFailure]]

 * @packageDocumentation
 * @module app/alert
 * @preferred
 * 
 */
import classes from "./alert.module.scss";
import copy from "./copy.json";

import * as React from "react";
import { useObservableState } from "observable-hooks";

import { Alert as AlertComponent } from "../../modules/components/Alert";
import { useTranslation } from "../../modules/utils";

import { clear, alerts$ } from "./stream";

export * from "./stream";

/**
 * React component for rendering a list of alerts that are stored
 * in the push-based stream (state) in `./stream.ts` Utilizes the
 * `useObservableState` hook provided by `observable-hooks` to read
 * from- and subscribe to state changes.
 *
 * @param props
 */
export const Alerts = (props: JSX.IntrinsicElements["div"] = {}) => {
  const [t] = useTranslation();
  const alerts = useObservableState(alerts$, []);

  const list = alerts.map((alert, index) => (
    <AlertComponent key={index} status={alert.status}>
      <div
        className={classes.alert}
        data-testid={`alert-${alert.status}-${index}`}
      >
        <div role="alert">{alert.node}</div>
        <button
          aria-label={t(copy["btn-clear-alert"])}
          title={t(copy["btn-clear-alert"])}
          onClick={() => clear(alert)}
          data-testid={`btn-alert-${alert.status}-${index}`}
        >
          <svg
            aria-hidden="true"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.58586 8.00008L0.292969 14.293L1.70718 15.7072L8.00008 9.41429L14.293 15.7072L15.7072 14.293L9.41429 8.00008L15.7072 1.70718L14.293 0.292969L8.00008 6.58586L1.70718 0.292969L0.292969 1.70718L6.58586 8.00008Z"
              fill={alert.status === "error" ? "#fff" : "#000"}
            />
          </svg>
        </button>
      </div>
    </AlertComponent>
  ));

  return alerts.length > 0 ? <div {...props}>{list}</div> : null;
};

export default Alerts;
