/**
 * ### Streams: Authentication
 *
 * This module provides a set of streams to manage anonymous- and authenticated user data.
 *
 * Login forms can use these streams to store username and password and API responses to
 * authentication requests.
 *
 * #### Types
 *
 * - [[AnonymousUser]]
 * - [[LoginOutcome]]
 * - [[LoginOperation]]
 * - [[Value]]
 *
 * #### Functions
 * - [[dotValue]]
 * - [[isRobot]]
 * - [[isHuman]]
 * - [[makeValue]]
 * - [[makeHumanValue]]
 * - [[makeRobotoValue]]
 *
 * #### Streams
 * - [[loginOperation$]]
 * - [[isAuthenticated$]]
 * - [[username$]]
 * - [[password$]]
 * - [[usernameValue$]]
 * - [[passwordValue$]]
 * - [[anonymousUser$]]
 * - [[user$]]
 *
 * #### Stream Readers
 * - [[getLoginOperation]]
 * - [[getUsername]]
 * - [[getPassword]]
 * - [[getAnonymousUser]]
 *
 * #### Stream Updaters
 * - [[setLoginOperation]]
 * - [[resetLoginOperation]]
 * - [[setUsername]]
 * - [[setPassword]]
 * - [[resetUsername]]
 * - [[resetPassword]]
 * - [[resetAnonymousUser]]
 *
 * @packageDocumentation
 * @module module/streams/authentication
 */

import { BehaviorSubject, combineLatest } from "rxjs";
import * as O from "fp-ts/lib/Option";
import * as E from "fp-ts/lib/Either";
import * as T from "fp-ts/lib/Task";
import { sequenceT } from "fp-ts/lib/Apply";
import { Failure } from "../apis/q";
import { AuthToken } from "../models/authToken";
import { map, filter, tap, take } from "rxjs/operators";
import {
  dot,
  forward,
  trim,
  negate,
  isNotNull,
  snd,
  fst,
  uuidv4,
} from "../utils";
import { flow, constant } from "fp-ts/lib/function";
import { reset$ } from "./reset";
import { pipe } from "fp-ts/lib/pipeable";
import { identity } from "io-ts";
import { AuthenticatedUser } from "../models/users";
import { login } from "../apis/login-api";
import { useObservableState } from "observable-hooks";

/**
 * Type representation of the the fields of a login form.
 */
export interface AnonymousUser {
  companyId: string;
  /** The username  */
  username: string;
  /** The password */
  password: string;
}

/**
 * An authentication request can succeed with a [[User]] or
 * fail with a [[Failure]]
 */
export type LoginOutcome = E.Either<Failure, [AuthenticatedUser, AuthToken]>;

/**
 * An authentication operation may or may not be available.
 */
export type LoginOperation = O.Option<LoginOutcome>;

/**
 * Value wrapper for change events. Updates to form values
 * such as the username or password are from the user or
 * by the system itself (reset, initial value, etc.)
 */
export type Value<T> = { value: T; robot: boolean };

/**
 * Getter function for reading the `.value` property of a [[Value]].
 */
export const dotValue = dot("value");

/**
 * Getter function for reading the `.robot` property of a [[Value]].
 */
export const isRobot = dot("robot");

/**
 * Getter function for reading the `.robot` property of a [[Value]] and negating it.
 */
export const isHuman = negate(isRobot);

/**
 * Stream for authentication operations.
 */
export const loginOperation$ = new BehaviorSubject<LoginOperation>(O.none);

export const companyId$ = new BehaviorSubject<Value<string>>({
  value: "",
  robot: true,
});

/**
 * Stream containing a [[Value]] for a username.
 */
export const username$ = new BehaviorSubject<Value<string>>({
  value: "",
  robot: true,
});

/**
 * Stream containing a [[Value]] for a password.
 */
export const password$ = new BehaviorSubject<Value<string>>({
  value: "",
  robot: true,
});

export const authToken$ = loginOperation$.pipe(
  map((maybeResponse) =>
    pipe(
      maybeResponse,
      O.map((response) =>
        pipe(response, E.fold(constant(O.none), flow(snd, O.some)))
      ),
      O.fold(constant(O.none), identity)
    )
  ),
  filter(isNotNull)
);

export const userInfo$ = loginOperation$.pipe(
  map((maybeResponse) =>
    pipe(
      maybeResponse,
      O.map((response) =>
        pipe(response, E.fold(constant(O.none), flow(fst, O.some)))
      ),
      O.fold(constant(O.none), identity)
    )
  ),
  filter(isNotNull)
);

export const companyIdValue$ = companyId$.pipe(map(dotValue));
/**
 * Stream operating over [[username$]] and mapping to it's `.value` property.
 */
export const usernameValue$ = username$.pipe(map(dotValue));

/**
 * Stream operating over [[password$]] and mapping to it's `.value` property.
 */
export const passwordValue$ = password$.pipe(map(dotValue));

/**
 * Stream combining [[usernameValue$]] and [[passwordValue$]] and mapping it to [[AnonymousUser]]
 */
export const anonymousUser$ = combineLatest(
  companyIdValue$,
  usernameValue$,
  passwordValue$
).pipe(
  map(
    ([companyId, username, password]): AnonymousUser => ({
      companyId,
      username,
      password,
    })
  )
);

// Getters

/**
 * Get the current value of the [[]loginOperation$] stram.
 */
export const getLoginOperation = loginOperation$.getValue.bind(loginOperation$);

export const getCompanyId = flow(
  companyId$.getValue.bind(companyId$),
  dotValue
);

/**
 * Get the current value of the [[username$]] stram. Maps to the streams [[Value]] `.value` property.
 */
export const getUsername = flow(username$.getValue.bind(username$), dotValue);

/**
 * Get the current value of the [[password$]] stram. Maps to the streams [[Value]] `.value` property.
 */
export const getPassword = flow(password$.getValue.bind(password$), dotValue);

/**
 * Get the current value of the [[username$]] and [[password$]] streams as an [[AnonymousUser]]
 */
export const getAnonymousUser = (): AnonymousUser => ({
  companyId: getCompanyId(),
  username: getUsername(),
  password: getPassword(),
});
// Setters

/**
 * Update the value of the [[loginOperation$]] stream.
 */
export const setLoginOperation = (nextLoginOperation: LoginOperation) =>
  loginOperation$.next(nextLoginOperation);

/**
 * Reset the value of the  [[loginOperation$]] stream.
 */
export const resetLoginOperation = setLoginOperation.bind(null, O.none);

/**
 * Wrap a value in a [[Value]] object.
 * @param robot Flag indicating the "author" of the value. This could be the
 * visitor or user, or a programmatically produced value. E.g. resetting a stream
 * with an empty value.
 */
export const makeValue = (robot: boolean): (<T>(value: T) => Value<T>) => (
  value
) => ({
  value,
  robot,
});

/**
 * Wrap a value in a [[Value]] object where `.robot` is `false`
 * indicating the value was produced by the user.
 */
export const makeHumanValue = makeValue(false);

/**
 * Wrap a value in a [[Value]] object where `.robot` is `true`
 * indicating the value was produced programatically.
 */
export const makeRobotoValue = makeValue(true);
export const setCompanyId = flow(
  trim,
  makeHumanValue,
  forward(companyId$.next.bind(companyId$)),
  dotValue
);

/**
 * Update the value of the [[username$]] stream.
 */
export const setUsername = flow(
  trim,
  makeHumanValue,
  forward(username$.next.bind(username$)),
  dotValue
);

/**
 * Update the value of the [[password$]] stream.
 */
export const setPassword = flow(
  trim,
  makeHumanValue,
  forward(password$.next.bind(password$)),
  dotValue
);

export const resetCompanyId = flow(
  trim,
  makeRobotoValue,
  forward(companyId$.next.bind(companyId$)),
  dotValue
).bind(null, "");

/**
 * Reset the value of the [[username$]] stream to an empty string
 * and indicate the value was updated programatically.
 */
export const resetUsername = flow(
  trim,
  makeRobotoValue,
  forward(username$.next.bind(username$)),
  dotValue
).bind(null, "");

/**
 * Reset the value of the [[passsword$]] stream to an empty string
 * and indicate the value was updated programatically.
 */
export const resetPassword = flow(
  trim,
  makeRobotoValue,
  forward(password$.next.bind(password$)),
  dotValue
).bind(null, "");

/**
 * Reset the value of the [[username$]] and  [[password$]] stream to an empty string
 * and indicate the value was updated programatically.
 */
export const resetAnonymousUser = flow(
  resetCompanyId,
  resetUsername,
  resetPassword
);

/**
 * Stream that operates over [[loginOperation$]] and maps it to a boolean flag if the operation was successful.
 */
export const isAuthenticated$ = loginOperation$.pipe(
  map((maybeResponse) =>
    pipe(
      maybeResponse,
      O.map((response) =>
        pipe(response, E.fold(constant(false), constant(true)))
      ),
      O.fold(constant(false), identity)
    )
  ),
  filter(isNotNull),
  tap(resetAnonymousUser)
);

/**
 * Streams the authenticated user. `None`, `Left`, and `null` values
 * are filtered meaning this stream does not have an initial value.
 */
export const user$ = loginOperation$.pipe(
  map((maybeResponse) =>
    pipe(
      maybeResponse,
      O.map((response) => pipe(response, E.fold(constant(null), identity))),
      O.fold(constant(null), identity)
    )
  ),
  filter(isNotNull)
);

// This is bad. An effect hidden in the code
// TODO Move to the root of the app
reset$.subscribe(flow(resetAnonymousUser, resetLoginOperation));

export const accessToken$ = authToken$.pipe(map(O.map(dot("access_token"))));

export const sessionId$ = accessToken$.pipe(map(flow(uuidv4, O.some)));

export const notAuthenticatedFetch = (_correlationId?: string) => (
  _requestInfo: string,
  _init: RequestInit = {}
): Promise<Response> =>
  Promise.reject(Error("Fetching but not authenticated."));

export const authenticatedFetch$ = combineLatest(
  accessToken$,
  userInfo$,
  sessionId$
).pipe(
  map((t) =>
    pipe(
      sequenceT(O.option)(...t),
      O.map(
        ([token, { companyId, username }, sessionId]) => (
          correlationId: string = "UnknownBmsApp"
        ) => async (path: string, init: RequestInit = {}) => {
          const response = await fetch(
            /^http/.test(path)
              ? path
              : `${process.env.REACT_APP_API_BASE_PATH}${path}`,
            {
              ...init,
              headers: Object.assign({}, init.headers || {}, {
                sessionId,
                sender: "SBXWEBUI",
                correlationId,
                companyId,
                username,
                "x-api-key":
                  process.env.REACT_APP_X_API_KEY ||
                  "yYPmit3v6t7JnzUulgqeMarf93sYQix49So49uSe",
                Authorization: `Bearer ${token}`,
              }),
            }
          );

          if (response.status === 401) {
            setTimeout(() => {
              loginOperation$.next(O.none);
            }, 1);
          }
          return response;
        }
      )
    )
  )
);

export const fetch$ = authenticatedFetch$.pipe(
  map((maybeFetch) => ({
    fetch: pipe(maybeFetch, O.fold(constant(notAuthenticatedFetch), identity)),
  }))
);

const makeMethod$ = (method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE") =>
  fetch$.pipe(
    map(
      ({ fetch }) => (correlationId?: string) => (
        path: string,
        init?: RequestInit
      ) => fetch(correlationId)(path, Object.assign({}, init || {}, { method }))
    ),
    map((_) => [_] as const)
  );

export const get$ = makeMethod$("GET");
export const post$ = makeMethod$("POST");
export const put$ = makeMethod$("PUT");

export const useAuthenticatedFetch = () =>
  useObservableState(fetch$, { fetch: notAuthenticatedFetch });

export const useOptionOfAuthenticatedFetch = () =>
  useObservableState(authenticatedFetch$, O.none);

export const useGet = () => useObservableState(get$, [notAuthenticatedFetch]);
export const usePost = () => useObservableState(post$, [notAuthenticatedFetch]);
export const usePut = () => useObservableState(put$, [notAuthenticatedFetch]);

export const authenticatedFetchTask = () =>
  authenticatedFetch$
    .pipe(filter(O.isSome), map(dot("value")), take(1))
    .toPromise();

export const authenticatedFetchTaskEither = pipe(
  authenticatedFetchTask,
  T.map(E.right)
);

export const useIsAuthenticated = () =>
  useObservableState(isAuthenticated$, false);

const anonymousSessionId = uuidv4();

export const anonymouseFetch = (correlationId: string = "sbui/unknown-app") => (
  path: string,
  init: RequestInit = {}
) =>
  fetch(`${process.env.REACT_APP_API_BASE_PATH}${path}`, {
    ...init,
    headers: Object.assign({}, init.headers || {}, {
      sessionId: anonymousSessionId,
      sender: "SBXWEBUI",
      correlationId,
      companyId: "SYSTEM",
      "x-api-key":
        process.env.REACT_APP_X_API_KEY ||
        "yYPmit3v6t7JnzUulgqeMarf93sYQix49So49uSe",
      "Content-Type": "application/json",
      username: "sb-user",
    }),
  });

export const logout = resetLoginOperation;

if (process.env.NODE_ENV === "development") {
  if (
    !!process.env.REACT_APP_SERVICEBENCH_COMPANY_ID &&
    !!process.env.REACT_APP_SERVICEBENCH_USERNAME &&
    !!process.env.REACT_APP_SERVICEBENCH_PASSWORD
  ) {
    if (!new URLSearchParams(window.location.search).get("nologin")) {
      companyId$.next(
        makeRobotoValue(process.env.REACT_APP_SERVICEBENCH_COMPANY_ID)
      );
      username$.next(
        makeRobotoValue(process.env.REACT_APP_SERVICEBENCH_USERNAME)
      );
      password$.next(
        makeRobotoValue(process.env.REACT_APP_SERVICEBENCH_PASSWORD)
      );
      pipe(
        login({
          companyId: companyId$.getValue().value,
          username: username$.getValue().value,
          password: password$.getValue().value,
        }),
        T.map(O.some),
        T.map((operation) => loginOperation$.next(operation))
      )();
    }
  }
}
