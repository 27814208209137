/**
 * @packageDocumentation
 * @module app/bms
 *
 */
import classes from "./bms.module.scss";

import * as React from "react";

/**
 * ReactJS props for the [[Body]] component.
 */
type propsBody = {
  /**
   * If true renders children horizontally and vertically centered. (If there is no overflow)
   */
  center?: boolean;
  /**
   * Content of the _Body_ component.
   */
  children: React.ReactNode;
  /**
   * Style properties. This does not override the main _div_ element wrapping
   * but a secondary wrapper element inside which the children are rendered.
   */
  style?: React.CSSProperties;
  /**
   * `size: 1` sets the max width of the wrapper to 453px (half of 906px layout). For
   * larger screens the width is set to 453px.
   *
   * `size: 2` follows the same pattern but at 906px;
   *
   * Setting size to 1 or 2 will center the content horizontally.
   */
  size?: 1 | 2;
  grid?: boolean;
  padded?: boolean;
  /**
   * Custom test id
   */
  "data-testid"?: string;
};

/**
 * _React Component_
 *
 * ### Body
 *
 * Helper component for laying out content within the project's design. Provides properties
 * to easily center content horizontally and vertically and provides options for sizing within
 * the 906px layout. Navigate to /layout-example to play around with the properties and get
 * an idea of how they work.
 *
 *
 * @param props
 */
export const Body = (props: propsBody) => (
  <div
    className={`${classes.contentBody} ${props.center ? classes.center : ""}`}
    data-size={props.size || ""}
    data-grid={!!props.grid}
    data-padded={!!props.padded}
    data-testid={props["data-testid"] || ""}
  >
    <div style={props.style}>{props.children}</div>
  </div>
);

export default Body;
